<template>
  <div
    class="quickShip"
    :style="styleCustomize"
  >
    <QuickShipTag
      :cartQsTagInfo="quickShipTagProps"
      :qsMallList="qsMallList"
      :lastQsTime="true"
    />
    <div 
      v-if="showLine" 
      class="quickShipLine"
    ></div>
  </div>
</template>
<script setup>
import QuickShipTag from 'public/src/pages/cartNew/components/list/item/QuickShipTag.vue'
import { useGetGuickShip } from 'public/src/pages/checkout/page_tpls/hook/useQuickShip.js'
import { computed } from 'vue'
const { quickShipInfoTag, qsMallList } = useGetGuickShip()

const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => ({})
  },
  text: {
    type: String,
    default: 'quickShip'
  },
  showLine: {
    type: Boolean,
    default: false 
  },
})

const styleCustomize = {
  ...props.styleConfig
}

const quickShipTagProps = computed(() => {
  return props.showLine ? 
    { 
      ...quickShipInfoTag.value.qsDataNoLabelProps,
      themVarIcon: { color: '#198055', size: '16px' }, 
      themVarText: { color: '#198055', 'font-weight': '700', ...props.styleConfig } 
    } : quickShipInfoTag.value.qsDataProps
})

</script>
<style lang="less">
  .quickShip{
    display: flex;
    width: fit-content;
    max-width: 100%;
    padding: 1px 3px;
    box-sizing: border-box;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    font-weight: 508;
    font-family: "SF Pro";
  }
  .quickShipText{
    font-style: italic;
    margin-left: 2px;
    padding-right: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .quickShipLine {
    width: 1px;
    height: .4rem;
    background: #8DC2AB;
    margin-left: 8px;
  }
</style>
